import React, { useRef, useEffect } from "react"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import "../style.css"
import ListHeader from "../components/listHeader"
import {glossaryTerms} from "../components/Shared/GlossaryTerms"


const Glossary = ({  data, location, termRef }) => {
 
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const defRef = useRef(termRef ? termRef : null)
  const siteTags = data.allTags.group
  const catalog = "Glossary"
  useEffect((termRef) => {
    if (termRef) {
      document.getElementById(defRef).scrollIntoView()
    }
  }, [])



  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Glossary" description="Glossary of terms and definitions referenced in Guide Projects posts and projects." />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
  
        <ListHeader catalog={catalog}>Glossary</ListHeader>
        
        {glossaryTerms.map((t, index) => {
          const taggedIn = siteTags.find(term => term.fieldValue === t.term)
          return(
          <div key={index} ref={defRef}>
            <GlossaryTerm>{t.term}</GlossaryTerm>
            <p>{t.definition}</p>
            <p>{t.expanded ? t.expanded : ""}</p>
            {t.references && "References:"}
            <ul>
              {t.references &&
                t.references.map((r, index) => {
                  const refName = r.name ? r.name : ""
                  const refLink = r.link ? r.link : ""
                  return (
                    <RefItem key={index}>
                      <a href={refLink} alt={refName}>
                        {refName}
                      </a>
                    </RefItem>
                  )
                })}
            </ul>
            
            <div>
            {taggedIn && taggedIn.totalCount > 0 && "Tagged in:"}
              <ul>
              {taggedIn && taggedIn.totalCount > 0 ? taggedIn.appearsIn.map((apin, index)=>(<RefItem key={index}><Link to={`/${apin.frontmatter.category}s/${apin.slug}`} alt={apin.frontmatter.title}>{apin.frontmatter.title}</Link></RefItem>)):""}
              </ul>
              
            </div>
          </div>
        )
        })}
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
    </Layout>
  )
}

export default Glossary
export const glossaryPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allTags: allMdx {
      totalCount
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        appearsIn: nodes {
          slug
          frontmatter {
            title
            published
            category
          }
        }
      }
    }
  }
`

const RefItem = styled.li`
  list-style: none;
`
const GlossaryTerm = styled.h2`
font-size:1.6rem;
color: var(--color-primary)
`